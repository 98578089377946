import { Container } from "./styles";

export function Experience() {
  return (
    <Container id="experience">
      <div className="experience-section">
          <h2 className="section-title">Experience</h2>

        <div className="experience-list">
            <div className="experience-item">
              <h3 className="job-title">Dynamic Soft | Backend Developer</h3>
              <p className="job-duration">June 2024 – Jan 2025 | Tashkent, Uzbekistan</p>
              <ul className="job-details">
                <li>Developed a REST API using NestJS and PostgreSQL to store data from learning management CRM, ERP, and E-Pos systems.</li>
                <li>Developed a web application using Node.js, NestJS, and PostgreSQL.</li>
                <li>Learned use of GitHub actions (CI/CD) to deploy applications 50% faster.</li>
                <li>Participated in 6+ ERP projects, including Avtoset.uz, Mebelhouse, and brightgalleryuz.com.</li>
                <li>Integrated third-party services like SMS, email, and payment systems (OCTO).</li>
              </ul>
            </div>

            <div className="experience-item">
              <h3 className="job-title">One Step | Backend Developer</h3>
              <p className="job-duration">March 2024 – June 2024 | Tashkent, Uzbekistan</p>
              <ul className="job-details">
                <li>Developed Telegram bots for a marketing agency.</li>
                <li>Built an ERP system integrated with Telegram Web App using Node.js, MongoDB, and ReactJS.</li>
              </ul>
            </div>

            <div className="experience-item">
              <h3 className="job-title">Nahr Group | Backend Developer</h3>
              <p className="job-duration">January 2024 – June 2024 | Tashkent, Uzbekistan</p>
              <ul className="job-details">
                <li>Developed moliyaviytahlil.uz website using NestJS, PostgreSQL, AWS S3 bucket, AWS server, and AHOST for domain name.</li>
                <li>Handled project management and backend development tasks.</li>
                <li>Used socket.io for real-time support chat.</li>
              </ul>
            </div>
        </div>
      </div>
    </Container>
  );
}
