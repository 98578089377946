import { Container } from "./styles"
import Illustration from "../../assets/illustration.svg"
import linkedin from '../../assets/linkedin.svg'
import githubIcon from '../../assets/github.svg'
import whatsapp from '../../assets/whatsapp.svg'
import Hello from '../../assets/Hello.gif'
import telegram from '../../assets/telegram.svg'
export function Hero() {
  return (
    <Container id="home">
      <div className="hero-text">
          <p>Hello <img src={Hello} alt="Hello" width="20px"/>, I'm</p>
          <h1>Oybek Berdiyev</h1>
          <h3>NodeJs Backend Developer</h3>
            <p className="small-resume"></p>
        
        <div className="social-media"><a
        href="https://www.linkedin.com/in/oybekberdiyev"
        target="_blank"
        rel="noreferrer"
      >
        <img src={linkedin} alt="Linkedin" />
      </a>
        <a
          href="https://github.com/oybekdevuz/"
          target="_blank"
          rel="noreferrer"
        >
          <img src={githubIcon} alt="GitHub" />
        </a>
        <a
          href="https://api.whatsapp.com/send/?phone=%2B998938376838&text=Assalomu+alaykum+Oybek"
          target="_blank"
          rel="noreferrer"
        >
          <img src={whatsapp} alt="Whatsapp" />
        </a>
        <a
          href="https://t.me/oybekdevuz"
          target="_blank"
          rel="noreferrer"
        >
          <img src={telegram} alt="telegram" />
        </a>
        </div>

      </div>
     

      <div className="hero-image">
          <img src={Illustration} alt="Ilustração" />
      </div>
     
    </Container>

  )
}