import styled from "styled-components";

export const Container = styled.section`
  margin-top: 8rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 4rem;

    h2{
    display: inline-block;
    margin-bottom: 2rem;
    // border-bottom: 0.2rem solid var(--blue);
    font-size :3rem;
    margin-top: 0rem;
    color: var(--green);
  }
    h3{
    display: inline-block;
    margin-bottom: 2rem;
    // border-bottom: 0.2rem solid var(--blue);
    font-size :3rem;
    margin-top: 0rem;
    color: var(--green);
  }

  .experience-section {
    max-width: 1200px;

    .section-title {
      font-size: 2.5rem;
      font-weight: bold;
      margin-bottom: 2rem;
      text-align: center;
    }

    .experience-list {
      display: flex;
      flex-direction: column;
      gap: 2.5rem;
    }

    .experience-item {
      padding: 1.5rem 1rem;
      border-left: 3px solid #ccc;

      .job-title {
        font-size: 2rem;
        font-weight: bold;
        margin-bottom: 0.5rem;
      }

      .job-duration {
        font-size: 1.6rem;
        margin-bottom: 1rem;
        color: #666;
      }

      .job-details {
        list-style: disc;
        padding-left: 2rem;
        font-size: 1.6rem;
        line-height: 1.8; /* Qulayroq o'qish uchun qatorlar orasini ochib qo'ydik */
        gap: 1rem;
      }

      .job-details li {
        margin-bottom: 0.8rem; /* Ro'yxat elementlari orasini ochib qo'ydik */
      }
    }
  }

  @media (max-width: 768px) {
    .experience-section {
      padding: 1rem;

      .experience-item {
        padding: 1rem 0.8rem;
      }

      .job-title {
        font-size: 1.8rem;
      }

      .job-duration {
        font-size: 1.4rem;
      }

      .job-details {
        font-size: 1.4rem;
        padding-left: 1.5rem;
      }
    }
  }
`;
