import { Container } from "./styles";
// import githubIcon from "../../assets/github.svg"
// import DownloadApp from '../../assets/download.png'
import externalLink from "../../assets/external-link.svg"


export function Project() {
  return (
    <Container id="project">
      <h2>My Projects</h2>
      <div className="projects">

          <div className="project">
            <header>
              <svg width="50" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" fill="none" stroke="#23CE6B " stroke-width="1" stroke-linecap="round" stroke-linejoin="round" ><title>Folder</title><path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path></svg>
              <div className="project-links">
                <a href="https://minni.uz/" target="_blank" rel="noreferrer">
                  <img src={externalLink} alt="Visit site" />
                </a>
              </div>
            </header>
            <div className="body">
              <h3>Minni.uz | Havolani qisqartiramiz</h3>
              <p>
              This site is designed to shorten links and generate a QR code for a link.
              </p>
            </div>
            <footer>
              <ul className="tech-list">
                <li>NodeJs</li>
                <li>Javasctipt</li>
                <li>React</li>
                <li>MongoDB</li>
              </ul>
            </footer>
          </div>

          <div className="project">
            <header>
              <svg width="50" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" fill="none" stroke="#23CE6B " stroke-width="1" stroke-linecap="round" stroke-linejoin="round" ><title>Folder</title><path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path></svg>
              <div className="project-links">
                <a href="https://t.me/minniuzbot" target="_blank" rel="noreferrer">
                  <img src={externalLink} alt="Visit site" />
                </a>
              </div>
            </header>
            <div className="body">
              <h3>Minniuz - telegram bot</h3>
              <p>
              This bot is minni.uz's telegram bot and is intended for some customers who don't want to visit sites.
              </p>
            </div>
            <footer>
              <ul className="tech-list">
              <li>NodeJs</li>
                <li>Javasctipt</li>
                <li>MongoDB</li>
              </ul>
            </footer>
          </div>

          <div className="project">
            <header>
              <svg width="50" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" fill="none" stroke="#23CE6B " stroke-width="1" stroke-linecap="round" stroke-linejoin="round" ><title>Folder</title><path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path></svg>
              <div className="project-links">
                <a href="https://t.me/uzbekistantaxirobot" target="_blank" rel="noreferrer">
                  <img src={externalLink} alt="Visit site" />
                </a>
              </div>
            </header>
            <div className="body">
              <h3>Uzbekistan Taxi - Raqamli taxi xizmati</h3>
              <p>
              The Uzbekistan telegram bot is for passengers and drivers traveling between regions of Uzbekistan, passengers can send requests to the driver through the telegram bot and deactivate their ads through the bot. Everything is systematized and digitized.
              </p>
            </div>
            <footer>
              <ul className="tech-list">
              <li>NodeJs</li>
              <li>Javasctipt</li>
              <li>Redis</li>
              <li>MongoDB</li>
              </ul>
            </footer>
          </div>

       

      </div>
    </Container>
  );
}