import { Container } from "./styles";
import postgresql from "../../assets/postgresql-logo.svg";
import github from "../../assets/github.svg";
import docker from "../../assets/docker-icon.svg";
import jsIcon from "../../assets/js-icon.svg";
import nodeIcon from "../../assets/node-icon.svg";
import mongodb from "../../assets/mongodb-icon.svg";
import typescriptIcon from "../../assets/typescript-icon.svg";
import mysql from "../../assets/mysql-icon.svg";
import gitlab from "../../assets/gitlab-icon.svg";
import { motion } from "framer-motion";

export function About() {

  const fadeInLeft = {
    initial: { opacity: 0, x: -50 },
    animate: { opacity: 1, x: 0 },
    transition: { duration: 0.5 },
  };

  const fadeInRight = {
    initial: { opacity: 0, x: 50 },
    animate: { opacity: 1, x: 0 },
    transition: { duration: 0.5 },
  };

  return (
    <Container id="about">
      <div className="about-text">
        <motion.div {...fadeInLeft}>
          <h2>About me</h2>
        </motion.div>
        <motion.div {...fadeInLeft}>
          <p>
            Assalamu alaykum! I'm Oybek, a backend developer who wants to bring
            digitization to people's lives. I want the people of Uzbekistan to
            live in a digitized world. My main skills are Nodejs, Javascript,
            Typescript.
          </p>
        </motion.div>
        <motion.div {...fadeInLeft}>
          <p>
            I not only build backend APIs but also offer telegram bot service
            for your business. If you need any telegram bot let's contact me.
          </p>
        </motion.div>
        <motion.div {...fadeInLeft}>
          <p>
            Quote: if I do a job in 30 minutes it's because I spent 10 years
            learning how to do that in 30 minutes. You owe me for the years, not
            the minutes.
          </p>
        </motion.div>

        <motion.div {...fadeInLeft}>
          <h3>Here are my main skills:</h3>
        </motion.div>

        <div className="hard-skills">
          {[nodeIcon, jsIcon, typescriptIcon, postgresql, mongodb, mysql, github, docker, gitlab].map(
            (icon, index) => (
              <motion.div
                key={index}
                className="hability"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <img src={icon} alt="Skill Icon" />
              </motion.div>
            )
          )}
        </div>
      </div>

      <div className="about-image">
        <motion.div {...fadeInRight}></motion.div>
      </div>
    </Container>
  );
}
